<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Analytics</h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr small m-0">
                    <div class="actions">
                        <ul>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="newsletter-respondents-page-filter" />
                            </li>
                            <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search"/>
                                <i class="fas fa-search"></i>
                            </li>
                            <li class="list_info">
                                {{ respondents.from ? respondents.from : 0 }} - {{ respondents.to ? respondents.to : 0 }} of <span>{{ respondents.total ? respondents.total : 0 }}</span>
                            </li>
                        </ul>
                    </div>
                    <div v-if="loader"><line-loader /></div>
                    <table class="standard">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody v-if="respondents.total">
                            <tr v-for="(respondent, r) in respondents.data" :key="r">
                                <td></td>
                                <td>
                                    <div class="user_wpr">
                                        <h4>{{ respondent.name ? respondent.name : '-' }}</h4>
                                    </div>
                                </td>
                                <td><div class="email_wpr">{{ respondent.email ? respondent.email : '-' }}</div></td>
                                <td>{{ respondent.phone ? respondent.phone : '-' }}</td>
                                <td>{{ moment(respondent.date_created).format('ll') }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3" class="px-4">No Records Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination" v-if="respondents.total">
                    <pagination v-model="params.page" :pages="respondents.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        data () {
            return {
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    id: '',
                },
                moment,
                isTyping: false,
            }
        },

        props: {
            modelValue: Boolean,
            selectedNewsletter: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.params = {
                                    per_page: 5,
                                    page: 1,
                                    search: '',
                                    id: vm.selectedNewsletter.id,
                                };

                    vm.getRespondents(vm.params);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getRespondents(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500)
            },

            isTyping (val) {
                const vm = this;

                if (!val && vm.params.search != null && (vm.params.search.length == 0 || vm.params.search.length >= 2)) {
                    vm.params.page = 1;

                    vm.getRespondents(vm.params);
                }
            },
        },

        computed: mapState({
            loader: state => state.newsletterModule.newsletterComponentLoader,
            respondents: state => state.newsletterModule.respondents,
        }),

        methods: {
            ...mapActions({
                getRespondents: 'newsletterModule/getRespondents',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['newsletter-respondents-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['newsletter-respondents-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getRespondents(vm.params);
            },
        }
    }
</script>

<style scoped>
    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
        width: auto;
        text-align: left;
    }
    .result_wpr.small table.standard td.action{
        width: 30px;
    }
    .result_wpr.small table.standard td.action i{
        width: auto;
        font-size: 13px;
    }
    .result_wpr.small table.standard td .drp_wrapper, .tempcard ul li .drp_wrapper{
        display: none;
    }
    .result_wpr.small table.standard td .drp_wrapper.active, .tempcard ul li .drp_wrapper.active{
        display: block;
    }
</style>
